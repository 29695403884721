import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, message, Form, Input, Select, Progress, Popover } from 'antd';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { fontLoadedVar } from 'graphql/cache';
import { loadProposalFont } from 'helpers/proposal';
import Uploader from 'components/Uploader';
import { fontListEnglish, fontListHebrew } from 'pages/Proposal/constants/constants';
import { resizeImageForS3 } from 'utils/uploadToS3';
import { SketchPicker } from 'react-color';
import { CHECK_DOMAIN_AVAILABILITY } from 'graphql/queries/userQueries';

import LogoUploadIcon from 'components/Icons/LogoUploadIcon';
import RemoveBinIcon from 'components/Icons/RemoveBinIcon';
import { LoadingOutlined, CaretDownOutlined } from '@ant-design/icons';

const Box = styled.div`
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #cbced8;
  width: 23px;
  background: ${(props) => props.color};
  height: 22px;
  border-radius: 4px;
`;

const Branding = ({
  form,
  user,
  language,
  projectsType,
  companyname,
  setCompanyName,
  brandcolor,
  brandlogo,
  setBrandLogo,
  colorValue,
  onColorChange,
  setDomainPrefix,
  domain,
  formFields,
}) => {
  const inputFileRef = useRef(null);
  const fontLoaded = useReactiveVar(fontLoadedVar);
  const [fontList, setFontList] = React.useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [isDomainAvailable, setIsDomainAvailable] = useState(true);
  const [upload, uploadStatus] = Uploader();

  const isHebrew = React.useMemo(
    () => formFields.proposalLanguage?.toLowerCase() === 'hebrew',
    [formFields]
  );

  React.useEffect(() => {
    if (!fontLoaded) {
      fontLoadedVar(true);
      loadProposalFont(
        isHebrew ? fontListHebrew : fontListEnglish,
        isHebrew ? 'hebrew' : 'english'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrelistLoad = (langChange) => {
    let prelist = [];

    if (langChange === 'hebrew' || formFields.proposalLanguage?.toLowerCase() === 'hebrew') {
      prelist = fontListHebrew;
    } else {
      prelist = fontListEnglish;
    }

    setFontList(prelist);
  };

  React.useEffect(() => {
    if (formFields.proposalLanguage) {
      handlePrelistLoad(formFields.proposalLanguage?.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);

  const [checkDomainAvailability, { loading: isCheckingDomain }] = useLazyQuery(
    CHECK_DOMAIN_AVAILABILITY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const domainAvailable = data.checkDomainAvailability;
        setIsDomainAvailable(domainAvailable);

        form.validateFields(['domain']);
      },
    }
  );

  const validateCompanyname = (rule, value, callback) => {
    if (value.length > 0 && value.trim().length === 0) {
      callback('Company name cannot just have spaces!');
    } else {
      callback();
    }
  };

  const validateDomainPrefix = (rule, value, callback) => {
    // Check if domain is too short or has invalid characters
    const hasInvalidCharacters = /[^a-zA-Z0-9-]/.test(value);
    const startsOrEndsWithSpace = /^\s|\s$/.test(value);
    const startsOrEndsWithHyphen = /^-|-$/.test(value);

    if (value.length > 0 && value.length < 4) {
      callback('Prefix is too short!');
    } else if (
      value.length > 0 &&
      (hasInvalidCharacters || startsOrEndsWithSpace || startsOrEndsWithHyphen)
    ) {
      callback('Prefix contains invalid characters!');
    } else if (value.length > 0 && !isDomainAvailable) {
      callback('Prefix already exists!');
    } else {
      callback();
    }
  };

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        window.removeEventListener('keydown', handleKeyCommand, true);
        setShowPicker(showPicker);
      }
    }
  };

  const formatDomainURL = (value) => {
    return value
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
      // .replace(/^-+|-+$/g, ''); Remove hyphen at start and end
  };

  const setDomainCompanyPrefix = (e) => {
    setCompanyName(e.target.value);
    const formattedURL = formatDomainURL(e.target.value);
    form.setFieldsValue({ domain: formattedURL });
    checkDomainAvailability({ variables: { prefix: formattedURL } });
  };

  const handleDomainChange = (event) => {
    const domain = formatDomainURL(event.target.value);
    form.setFieldsValue({ domain: domain });

    debouncedDomainCheck(domain);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDomainCheck = useCallback(
    _.debounce((domain) => {
      checkDomainAvailability({ variables: { prefix: domain } });
    }, 500),
    [checkDomainAvailability]
  );

  const uploadUserPhoto = () => {
    inputFileRef.current.click();
  };

  const detachUpload = () => {
    setBrandLogo('');
    form.setFieldsValue({ brandlogo: '' });
  };

  const onImageSelect = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (!file) {
      return;
    }

    if (validity.valid) {
      const size = { width: 640, height: 480 };

      try {
        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'profile',
          size,
        });

        const { url } = await upload(reSizedImage, `profile/${user._id}`);
        setBrandLogo(url);
        form.setFieldsValue({ brandlogo: url });
      } catch (error) {
        message.error(error.message);
      } finally {
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
      }
    }
  };

  return (
    <>
      <Col xs={24} className="upload-container">
        <Form.Item label="" colon={false} help="Upload your logo (max 2 MB)">
          {uploadStatus.status === 'uploading' ? (
            <Progress type="circle" percent={uploadStatus.percent} />
          ) : (
            <div>
              {brandlogo ? (
                <div
                  className="logo-uploader has-image"
                  style={{
                    backgroundImage: `url(${brandlogo})`,
                  }}
                  onClick={uploadUserPhoto}>
                  <span
                    className="remove-logo"
                    onClick={(e) => {
                      e.stopPropagation();
                      detachUpload();
                    }}>
                    <RemoveBinIcon />
                  </span>
                </div>
              ) : (
                <div className="logo-uploader" onClick={uploadUserPhoto}>
                  <span className="upload-icon">
                    <LogoUploadIcon />
                  </span>
                </div>
              )}
            </div>
          )}
        </Form.Item>
      </Col>
      <Form.Item
        label="Company Name"
        name="companyname"
        labelCol={false}
        rules={[
          { required: true, message: 'Company name cannot be empty!' },
          { validator: validateCompanyname },
        ]}
        shouldUpdate>
        <Input value={companyname} onChange={setDomainCompanyPrefix} />
      </Form.Item>

      <Form.Item
        label="Proposal Link Prefix"
        name="domain"
        rules={[
          { required: true, message: 'Prefix cannot be empty!' },
          { validator: validateDomainPrefix },
        ]}
        labelCol={false}
        shouldUpdate>
        <Input
          addonAfter={!isCheckingDomain ? '.goprospero.com' : <LoadingOutlined />}
          placeholder="yourbrand"
          onChange={handleDomainChange}
          value={domain}
        />
      </Form.Item>

      <Form.Item label="Font" name="defaultProposalFont" labelCol={false} shouldUpdate>
        <Select
          placeholder="Poppins"
          suffixIcon={<CaretDownOutlined color="red" />}
          className="select-custom-suffix">
          {fontList.map((font) => (
            <Select.Option key={font.family} style={{ fontFamily: font.family }}>
              {font.family}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Color (signature button)"
        className="brand-color-input"
        name="brandcolor"
        labelCol={false}>
        <Input
          value={colorValue}
          onClick={() => setShowPicker(true)}
          readOnly
          prefix={
            <>
              <Popover
                placement="bottomLeft"
                onVisibleChange={() => {
                  if (!showPicker) {
                    window.addEventListener('keydown', handleKeyCommand, true);
                  } else {
                    window.removeEventListener('keydown', handleKeyCommand, true);
                  }
                  setShowPicker(!showPicker);
                }}
                content={<SketchPicker color={colorValue} onChange={onColorChange} />}
                trigger="click"
                visible={showPicker}
                overlayClassName="editor-color-picker">
                <div className="brand-color-selector">
                  <Box color={colorValue} />
                </div>
              </Popover>
            </>
          }
        />
      </Form.Item>
      <input
        type="file"
        className="file_bag"
        accept="image/*"
        ref={inputFileRef}
        onChange={onImageSelect}
        style={{ display: 'none', position: 'absolute', left: -10000 }}
      />
    </>
  );
};

Branding.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  projectsType: PropTypes.instanceOf(Array),
  clickedCheckbox: PropTypes.instanceOf(Array),
  setClickedCheckbox: PropTypes.func,
  colorValue: PropTypes.string,
  onColorChange: PropTypes.func.isRequired,
};

export default Branding;
