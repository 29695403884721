// used for grid breakpoints in dashboard header, skeleton, proposal item,
export const proposalItem = {
  proposal: { xs: 24, lg: 11, xl: 7 },
  client: { xs: 24, lg: 8, xl: 5 },
  watched: { xl: 2 },
  opened: { xl: 2 },
  quote: { xs: 24, xl: 3 },
  status: { xs: 24, lg: 3 },
  action: { xs: 2, lg: 1 },
};
