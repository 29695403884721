import React from 'react';
import PropTypes from 'prop-types';

import './PageHeader.scss';

const PageHeader = ({ 
  pageTitle,
  pageLabel,
  showButton,
 }) => (
  <div className="page-header">
    <div className="page-title-wrapper">
      <div className="page-title-conatiner">
        <h1 className="page-title">{pageTitle}</h1>
        <h5 className="page-label">{pageLabel}</h5>
      </div>
      <div className="color-divider" />
    </div>
    {showButton && <div className="button-wrapper">{showButton}</div>}
  </div>
);

PageHeader.defaultProps = {
  showButton: false,
  pageLabel: '',
};

PageHeader.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  showButton: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  pageLabel: PropTypes.string,
};

export default PageHeader;
