import React, { useState, useEffect } from 'react';
import { Modal, Form, Steps, Layout, Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { fromAppVar } from 'graphql/cache';
import { UserInfo, ProjectInfo, Branding } from './components';
import Button from 'components/Button';
import utils from 'utils/utils';
import helpers from 'helpers';
import CanvasConfetti from 'components/CanvasConfetti';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import CurrentProgressIcon from 'components/Icons/CurrentProgressIcon';
import WaitProgressIcon from 'components/Icons/WaitProgressIcon';
import projectsType from 'constants/project-type';
import SignupFinish from './components/SignupFinish/SignupFinish';
import images from 'constants/images';

import './OnboardingWizard.scss';

const { Step } = Steps;
const { Sider, Content } = Layout;

const OnboardingWizard = ({
  user,
  saveUserProfileInfo,
  createProposal,
  addUser,
  onCancel,
  refetchUser,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [clickedCheckbox, setClickedCheckbox] = useState(
    projectsType.map((_, index) => (index < projectsType.length - 1 ? true : false))
  );
  const [colorValue, changeColor] = useState(user?.branding?.buttonColor || '#005AFF');

  // Form Fields State
  const [formFields, setFormFields] = useState({
    proposalLanguage:
      user?.profile?.language ||
      window.sessionStorage.getItem('language.value')?.toLowerCase() ||
      'english',
    currency: user?.profile?.currency || '$',
    dateFormat: user?.profile?.dateFormat || 'MM/DD/YYYY',
    domain: user?.domain || '',
    companyname: user?.profile?.companyname || '',
    defaultProposalFont: user?.profile?.defaultProposalFont || 'Poppins',
    brandlogo: user?.branding?.companyLogo || '',
    password: '',
    fullname: user?.profile?.name || '',
    brandcolor: colorValue,
    termsValue: user?.profile?.terms || false,
    wixConnected: utils.getURLParam('wix') ? true : false,
    where: user?.profile?.where,
  });

  // const checkForErrors = async () => {
  //   try {
  //     await form.validateFields();
  
  //     setIsNextDisabled(!domainValidationState);
  //   } catch (info) {
  //     setIsNextDisabled(info.errorFields.length > 0 || !domainValidationState);
  //   }
  // };

  const onColorChange = (selectedColor) => {
    const {
      rgb: { r, g, b, a },
    } = selectedColor;
    const hexa = helpers.rgbaToHexA(r, g, b, a);
    changeColor(hexa);
    setFormFields({ ...formFields, brandcolor: hexa });
    form.setFieldsValue({ brandcolor: hexa });
  };

  useEffect(() => {
    const onboardState = localStorage.getItem('onboardingState');
    if (onboardState) {
      const { currentStep } = JSON.parse(onboardState);
      setCurrentStep(currentStep);
    }
  }, []);

  // Handle URL Parameters and User Refetching
  useEffect(() => {
    if (
      fromAppVar() === 'monday' &&
      (user?.monday === null || Object.keys(user?.monday).length === 0)
    ) {
      const authUrl = `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MONDAY_REDIRECT_URI}`;
      utils.refetchUserOnClosingTab(authUrl).then(() => {
        refetchUser();
      });
    }
  }, [refetchUser, user?.monday]);

  // Step Titles and Content Components
  const steps = React.useMemo(
    () => [
      {
        title: 'Sign up',
        content: null,
      },
      {
        title: 'Information',
        heading: 'Welcome 🥳',
        content: (
          <UserInfo
            fullname={formFields.fullname}
            setFullname={(value) => setFormFields((prev) => ({ ...prev, fullname: value }))}
            termsValue={formFields.termsValue}
            setTermsValue={(value) => setFormFields((prev) => ({ ...prev, termsValue: value }))}
            password={formFields.password}
            setPassword={(value) => setFormFields((prev) => ({ ...prev, password: value }))}
          />
        ),
      },
      {
        title: 'Account setup',
        heading: 'Set Up Your Account 📋',
        content: (
          <ProjectInfo
            user={user}
            form={form}
            projectsType={projectsType}
            setLanguage={(value) => setFormFields((prev) => ({ ...prev, proposalLanguage: value }))}
            setDateFormat={(value) => setFormFields((prev) => ({ ...prev, dateFormat: value }))}
            setCurrency={(value) => setFormFields((prev) => ({ ...prev, currency: value }))}
            clickedCheckbox={clickedCheckbox}
            setClickedCheckbox={setClickedCheckbox}
            currency={formFields.currency}
          />
        ),
      },
      {
        title: 'Branding',
        heading: 'Customize your branding 🎨',
        content: (
          <Branding
            user={user}
            form={form}
            language={formFields.proposalLanguage}
            companyname={formFields.companyname}
            setCompanyName={(value) => setFormFields((prev) => ({ ...prev, companyname: value }))}
            setDefaultProposalFont={(value) =>
              setFormFields((prev) => ({ ...prev, defaultProposalFont: value }))
            }
            setBrandLogo={(value) => setFormFields((prev) => ({ ...prev, brandlogo: value }))}
            brandlogo={formFields.brandlogo}
            brandcolor={formFields.brandcolor}
            onColorChange={onColorChange}
            colorValue={colorValue}
            domain={formFields.domain}
            setDomainPrefix={(value) => setFormFields((prev) => ({ ...prev, domain: value }))}
            formFields={formFields}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formFields, clickedCheckbox]
  );

  const customDot = (_, info) => {
    switch (info.status) {
      case 'finish':
        return <CircleTickIcon />;
      case 'process':
        return <CurrentProgressIcon />;
      case 'wait':
        return <WaitProgressIcon />;
      default:
        return null;
    }
  };

  const updateLocalState = (step) => {
    if (step == null) {
      localStorage.removeItem('onboardingState');
    } else {
      const state = {
        currentStep: step,
      };
      localStorage.setItem('onboardingState', JSON.stringify(state));
    }
  };

  // Handle Form Submission
  const submitProfileInfo = (values) => {
    const finalStep = values.companyname && values.companyname.trim() !== '';

    // Prepare values based on current step
    let updatedValues = { ...formFields, ...values };
    if (finalStep) {
        updatedValues.enableAITool = !!(updatedValues['proposalLanguage'] === 'english');
        updatedValues.onBoardComplete = true;
        setIsSaving(true);
    }
    // Merge form values into formFields state
    setFormFields((prev) => ({ ...prev, ...values }));

    if (currentStep === 2) {
      // Handle ProjectInfo step
      const ptype = projectsType
        .filter((_, index) => clickedCheckbox[index])
        .map((project) => project.key)
        .join(' ');
      updatedValues.ptype = ptype;
    }

    // Save user profile information
    saveUserProfileInfo(updatedValues);

    if (finalStep) {
      updateLocalState(null);
      setTimeout(() => {
        setIsSaving(false);

        setCurrentStep((prev) => prev + 1);
      }, 1000);
    } else {
      updateLocalState(currentStep + 1);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 2 && !clickedCheckbox.some((checked) => checked)) {
      return;
    }
    form
      .validateFields()
      .then((values) => {
        submitProfileInfo(values);
      })
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo);
      });
  };

  const renderFinish = () => (
    <SignupFinish
      wixConnected={formFields.wixConnected}
      createNewProposal={createProposal}
      addUser={addUser}
      onCancel={onCancel}
    />
  );

  return (
    <>
      {currentStep === steps.length && <CanvasConfetti event={'ONBOARDED'} delay={1000} />}
      <Modal
        className={`simple-info-modal sign-up-info-modal ${
          currentStep !== steps.length ? '' : 'final'
        }`}
        centered
        visible
        footer={null}
        closeIcon={null}
        closable={false}
        maskClosable={false}>
        <Content className="wizard-content">
          <Layout className="account-onboard-layout">
            {currentStep !== steps.length && (
              <Sider
                width={200}
                className="onboard-wizard-steps"
                breakpoint="sm"
                collapsedWidth="50">
                <Row className="header-signup">
                  <Col className="logo">
                    <img src={images.LOGO} className="full-logo" alt="prospero-logo" height="16" />
                    <img
                      src={images.LOGO_SMALL}
                      className="small-logo"
                      alt="prospero-logo"
                      height="25"
                    />
                  </Col>
                </Row>
                <Steps current={currentStep} progressDot={customDot} direction="vertical">
                  {steps.map((step) => (
                    <Step key={step.title} title={step.title} />
                  ))}
                </Steps>
              </Sider>
            )}
            <Col className="onboard-form">
              {currentStep < steps.length ? (
                <Form layout="vertical" initialValues={formFields} form={form} autoComplete="off">
                  <div className="wizard-content-container">
                    <div className="heading-wrap">
                      <h1 className="heading">{steps[currentStep].heading}</h1>
                      <div className="color-divider" />
                    </div>
                    {steps[currentStep].content}
                  </div>
                  <div className={`button-wrapper ${currentStep === 0 ? 'welcome-form' : ''}`}>
                    {currentStep > 1 ? (
                      <Button
                        type="primary"
                        className="cancel"
                        onClick={handlePrev}
                        htmlType="button"
                        text={''}
                        icon={
                          <div className="button-icon-right">
                            <LeftOutlined />
                          </div>
                        }
                      />
                    ) : (
                      <>&nbsp;</>
                    )}
                    <Button
                      type="primary"
                      onClick={() => handleNext()}
                      htmlType="button"
                      text={currentStep === 3 ? 'Finish' : 'Continue'}
                      loading={isSaving}
                    />
                  </div>
                </Form>
              ) : (
                renderFinish()
              )}
            </Col>
          </Layout>
        </Content>
      </Modal>
    </>
  );
};

OnboardingWizard.propTypes = {
  user: PropTypes.object.isRequired,
  saveUserProfileInfo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  createProposal: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  refetchUser: PropTypes.func.isRequired,
};

export default OnboardingWizard;
