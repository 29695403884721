/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" viewBox="0 0 29 24" fill="#2F2C88" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.8,8.5h-7.3l-1-0.6l-0.5-1C9.8,6.8,9.7,6.6,9.6,6.5C9.5,6.4,9.4,6.3,9.3,6.1L9.2,6l-0.1,0
			C8.9,5.9,8.5,5.7,8.1,5.7h-5C2.4,5.7,1.8,6,1.3,6.5S0.5,7.6,0.5,8.2V21c0,0.7,0.2,1.3,0.8,1.8c0.5,0.5,1.1,0.8,1.8,0.8h15.6
			c0.7,0,1.3-0.2,1.8-0.5l0.1-0.1c0.5-0.5,0.8-1.1,0.8-1.8V11.1c0-0.7-0.2-1.3-0.8-1.8C20.1,8.8,19.4,8.5,18.8,8.5z M2.7,8.3
			c0,0,0-0.1,0.1-0.2C2.9,8,3,8,3.1,8H8c0,0,0,0,0,0l0.8,1.5l2,1.3h8c0.1,0,0.2,0,0.3,0.2l0,0c0,0,0,0.1,0,0.1v10.1
			c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.3,0.1H3.1c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1c0,0,0-0.1,0-0.1V8.3z"/>
		<rect x="16.1" y="0.4" width="2.2" height="5.1"/>
		<rect x="20.5" y="3.7" transform="matrix(0.6263 -0.7796 0.7796 0.6263 4.9321 19.9647)" width="5.5" height="2.2"/>
		<rect x="23.2" y="9.3" transform="matrix(0.9901 -0.1407 0.1407 0.9901 -1.2059 3.7315)" width="5.1" height="2.2"/>
</svg>
);

const AddFolder = (props) => <Icon component={svg} {...props} />;

export default AddFolder;
