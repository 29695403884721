import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Divider, Col, Row, notification } from 'antd';
import { useIntercom } from 'react-use-intercom';

import { CONTENT_LIBRARY_ADD_CHILD } from 'graphql/mutations/contentLibraryMutations';
import { GET_TREEVIEW_DATA } from 'graphql/queries/contentLibraryQueries';
import { getUserId } from 'graphql/cache';

import FolderIcon from 'components/Icons/FolderIcon';
import RightIcon from 'components/Icons/RightIcon';
import AddIcon from 'components/Icons/AddIcon';
import AddFolder from 'components/Icons/AddFolder';
import UploadContentLibraryItem from 'components/UploadContentLibraryItem/UploadContentLibraryItem';
import Prompt from 'components/Prompt';

import './LibraryItems.scss';

const LibraryItems = ({
  onBackClick,
  ChildComponent,
  type,
  coversRef,
  coverType,
  saveProposal,
}) => {
  const [selectedFolder, setSelectedFolder] = useState('');
  const [currentFolderData, setCurrentFolderData] = useState([]);
  const [path, setPath] = useState();
  const [showUploadModal, showOrHidesUploadModal] = useState(false);
  const [showOrHidesNewFolderModal, setShowOrHidesNewFolderModal] = useState(false);

  const { trackEvent } = useIntercom();

  const { data, refetch } = useQuery(GET_TREEVIEW_DATA, {
    variables: {
      type: type === 'media' ? 'medias' : type,
    },
  });

  const [addChild] = useMutation(CONTENT_LIBRARY_ADD_CHILD, {
    onCompleted() {
      setShowOrHidesNewFolderModal(false);
      notification.success({
        description: 'Created new folder successfully.',
      });
      refetch({
        variables: {
          type: type === 'media' ? 'medias' : type,
        },
      });
    },
    onError: () => {
      notification.error({
        description: 'Created new folder failed.',
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (data && data.fetchContentLibrary) {
        if (selectedFolder) {
          const folders = [];
          let key = '';
          setCurrentFolderData(
            selectedFolder.split('.').reduce((o, k) => {
              key = `${key ? `${key}.` : ''}${k}`;
              const child = o && o[k];
              if (child && child.title)
                folders.push({
                  title: child.title,
                  key: key,
                });
              return child;
            }, data.fetchContentLibrary)?.children || []
          );
          setPath(folders);
        } else {
          setPath(null);
          setCurrentFolderData(data.fetchContentLibrary.children);
        }
      } else {
        setCurrentFolderData([]);
      }
    })();
  }, [selectedFolder, data, setCurrentFolderData]);

  return (
    <div className="covers-library-section">
      <div>
        <Row className="folder-path">
          <Col className="path">
            {path ? (
              [{ title: type }, ...path].map((p, i) => (
                <>
                  {!!i && <span key={p.key + 'span'}>/</span>}
                  <span
                    className="folder-path-name"
                    key={p.key}
                    onClick={() => setSelectedFolder(p.key)}>
                    {p.title}
                  </span>
                </>
              ))
            ) : (
              <span className="folder-path-name">{type}</span>
            )}
          </Col>
          <Col
            className="folder-path-buttons"
            style={
              type?.toLowerCase() === 'covers' || type?.toLowerCase() === 'media'
                ? { display: 'flex', justifyContent: 'flex-end', flex: 2 }
                : {}
            }>
            {(type?.toLowerCase() === 'covers' || type?.toLowerCase() === 'media') && (
              <Button
                className="medium-btn secondary-btn icon-right"
                icon={<AddIcon />}
                onClick={() => showOrHidesUploadModal(true)}>
                Upload
              </Button>
            )}
            <Button
              className="medium-btn grey-btn back-button"
              onClick={() =>
                selectedFolder
                  ? setSelectedFolder(selectedFolder.split('.').slice(0, -2).join('.'))
                  : onBackClick()
              }>
              Back
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      {currentFolderData &&
        currentFolderData.map((folder, i) => {
          return folder.title ? (
            <div
              key={i}
              className="folder"
              onClick={() =>
                setSelectedFolder(`${selectedFolder ? `${selectedFolder}.` : ''}children.${i}`)
              }>
              <div>
                <FolderIcon />
                {folder.title}
              </div>
              <RightIcon className="folder-path-arrow" />
            </div>
          ) : (
            <></>
          );
        })}

      {type?.toLowerCase() === 'covers' && (
        <Row>
          <Button
            className="large-btn grey-btn prosper-button-add-folder-icon"
            icon={<AddFolder />}
            size="large"
            block
            onClick={() => setShowOrHidesNewFolderModal(true)}>
            New Folder
          </Button>
        </Row>
      )}

      {ChildComponent({ selectedFolder, type })}

      <UploadContentLibraryItem
        type={type}
        selectedFolderKey={selectedFolder}
        showUploadModal={showUploadModal}
        cancelUploadModal={() => showOrHidesUploadModal(false)}
        selectedCoverType={coverType}
        onSuccess={async (urls) => {
          trackEvent('click-upload-cover');
          if (urls?.length && type === 'covers') {
            saveProposal({ 'draft.header.cover': urls[0].url });
          }
          if (coversRef && coversRef.current) {
            await coversRef.current.reloadItems();
          }
        }}
      />

      {showOrHidesNewFolderModal && (
        <Prompt
          title="Enter Folder Name"
          visible={showOrHidesNewFolderModal}
          cancelText="CANCEL"
          confirmText="ADD"
          handleCancel={() => setShowOrHidesNewFolderModal(false)}
          handleConfirm={(title) => {
            if (title) {
              addChild({
                variables: {
                  child: { title, uid: getUserId() },
                  key: `${selectedFolder}${selectedFolder ? '.' : ''}children`,
                  id: data?.fetchContentLibrary?._id,
                },
              });
            }
          }}
        />
      )}
    </div>
  );
};

export default LibraryItems;
