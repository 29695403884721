import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

import { List, Avatar, Divider, message, Button } from 'antd';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import { useHistory } from 'react-router-dom';

import { getUserShortName } from 'helpers/users';
import Loader from 'components/Loader';
import utils from 'utils/utils';

import './UserActivityList.scss';

const UserActivityList = ({
  user,
  isHeader,
  teamsActivity,
  activitiesByDays,
  fetchMoreTeamsActivity,
  offset,
  setOffset,
  activityCount,
  usersTeam,
  loading,
  hasMore,
  handleInfiniteOnLoad,
  setActiveOption,
}) => {
  const history = useHistory();

  const totalActivities = _.flatten(_.values(activitiesByDays)).length;

  const handleProposalClick = (proposal, type) => {
    let route = '';
    if (
      type === 'trashed' ||
      type === 'deleted' ||
      proposal?.proposalInfo?.state === 'deleted'
    ) {
      return message.error('Proposal not found');
    } else if (!Object.keys(proposal?.proposalInfo?.draft || {}).length) {
      route = `/w/${proposal?.proposalInfo?.pid}`;
    } else {
      route = `/pd/${proposal?._id}`;
    }
    history.push(route);
  };

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={offset}
      loadMore={handleInfiniteOnLoad}
      hasMore={!loading && hasMore}
      useWindow={false}>
      {_.flatten(
        _.toArray(
          _.map(activitiesByDays, (activityList, dateName) => (
            <div className="user-activity-list" key={dateName}>
              <Divider orientation={isHeader ? 'left' : 'center'}>
                {dateName}
              </Divider>
              <List
                itemLayout="horizontal"
                dataSource={activityList}
                renderItem={(item) => {
                  const userName =
                    item?.user?.userInfo?.profile.name || item?.user?.name;

                  const proposalName = utils.changeVariable(
                    item?.proposal?.proposalInfo?.draft?.variables,
                    item?.proposal?.name
                  );

                  let userPicture =
                    item.user._id === user._id
                      ? user.profile.image
                      : item?.user?.userInfo?.profile?.image ||
                        item?.user?.profile?.image;

                  if (!userPicture && usersTeam?.length) {
                    for (let index = 0; index < usersTeam.length; index++) {
                      const teamUser = usersTeam[index];
                      if (teamUser._id === item?.user?._id) {
                        userPicture = teamUser.profile.image;
                        break;
                      }
                    }
                  }

                  return (
                    <List.Item key={item?.proposal?._id}>
                      <List.Item.Meta
                        avatar={
                          <div className="user-activity-list-avatar-container">
                            {userPicture ? (
                              <Avatar src={userPicture} />
                            ) : (
                              <Avatar>{getUserShortName({ name: userName })}</Avatar>
                            )}
                            <div className="user-activity-list-avatar-content">
                              <a>{userName} </a>
                              <p>{moment(item.createdAt).format('hh:mm A')}</p>
                            </div>
                          </div>
                        }
                        title={
                          <span>
                            {item.type === 'template' ? 'Created ' : ''}
                            {item.type} proposal
                            <a
                              onClick={() =>
                                handleProposalClick(item?.proposal, item?.type)
                              }>
                              {' '}
                              {proposalName} ({item?.proposal?._id})
                            </a>
                          </span>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          ))
        )
      )}

      {!isHeader && hasMore && totalActivities > 5 && (
        <Button
          className="load-more large-btn grey-btn"
          loading={loading}
          onClick={() => handleInfiniteOnLoad()}>
          Load More
        </Button>
      )}

      {loading && hasMore && <Loader className="user-activity-list-loader" />}

      {isHeader && totalActivities > 3 && (
        <div className="view-all-button">
          <span className="fade-patch" />
          <div className="button-action">
            <span onClick={() => {
              history.push('/activity');
              setActiveOption(false);
            }}>View all activity <ArrowRightIcon className="color-red" /></span>
          </div>
        </div>
      )}
    </InfiniteScroll>
  );
};

UserActivityList.defaultProps = {
  offset: 0,
  isHeader: false,
  activityCount: 0,
  fetchMoreTeamsActivity: () => {},
  handleInfiniteOnLoad: () => {},
  usersTeam: [],
};

UserActivityList.propTypes = {
  user: PropTypes.instanceOf(Object),
  offset: PropTypes.number,
  isHeader: PropTypes.bool,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  activityCount: PropTypes.number,
  fetchMoreTeamsActivity: PropTypes.func.isRequired,
  setOffset: PropTypes.func.isRequired,
  teamsActivity: PropTypes.instanceOf(Array).isRequired,
  activitiesByDays: PropTypes.instanceOf(Object).isRequired,
  usersTeam: PropTypes.instanceOf(Object),
  setActiveOption: PropTypes.func.isRequired,
};

export default UserActivityList;
