import { NavLink } from 'react-router-dom';
import images from 'constants/images';

const Branding = ({ logo = images.LOGO_SMALL, className }) => {
  return (
    <div className="sidebar-branding">
      <NavLink to="/">
        <img src={logo} className={className} alt="prospero-logo" />
      </NavLink>
    </div>
  );
};

export default Branding;
