import PageHeader from 'components/PageHeader';
import './Emails.scss';
import { Button, Row } from 'antd';
import { AccordionContent } from './Components/Accordion';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_EMAIL_SETTINGS } from 'graphql/queries/emailSettingsQueries';
import Loader from 'components/Loader';
import { GeneralSettingsModal } from './Modals/GeneralSettings';
import { useEffect, useState } from 'react';
import { ProposalEmailIcon, ReminderIcon, SignedCopyIcon } from 'components/Icons';
import { userVar, cache } from 'graphql/cache';

const Emails = () => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const user = useReactiveVar(userVar);

  useEffect(() => {
    document.title = 'Prospero - Email Settings';
    cache?.evict({
      id: 'ROOT_QUERY',          // Root of the cache for queries
      fieldName: 'fetchEmailSettings' // Field corresponding to the query result
    });
    fetchEmailSettings();
    return () => cache.evict({
      id: 'ROOT_QUERY',          // Root of the cache for queries
      fieldName: 'fetchEmailSettings' // Field corresponding to the query result
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const emails = {
    proposal: {
      title: 'Proposal email',
      icon: <ProposalEmailIcon />,
      items: {
        publishedProposalToProspect: {
          title: 'Official email request to the prospect to sign the proposal link',
          description: 'This email will be sent if you choose to notify the prospect when publishing a proposal'
        },
      },
    },
    reminder: {
      title: 'Reminders',
      icon: <ReminderIcon />,
      items: {
        reminderToProspect: {
          title: '1st email reminder to the prospect to sign the proposal',
          description: 'This reminder will be sent [X] days after the proposal is sent if it hasn\'t been signed',
          isReminder: true,
        },
        secondReminderToProspect: {
          title: '2nd email reminder to the prospect to sign the proposal',
          description: 'This reminder will be sent [X] days after the proposal is sent if it hasn\'t been signed',
          isReminder: true,
        },
      },
    },
    signed: {
      title: 'Signed copy',
      icon: <SignedCopyIcon />,
      items: {
        signedPDFToProspect: {
          title: 'Email confirmation with the signed PDF & link to the prospect',
          description: 'This email will be sent to your prospect upon signing the proposal',
          hasAttachment: true,
        },
      },
    },
  };

  const [fetchEmailSettings,{ data: { fetchEmailSettings: emailSettings } = {}, loading }] = useLazyQuery(GET_EMAIL_SETTINGS);

  const isDisabled = user?.paymentStatus?.canLock || !!user?.teamId;

  return !loading && emailSettings ? (
    <div className="container">
      <div className="emails-wrapper">
        <PageHeader pageTitle="Email Templates & Reminders" />
        <div className="accordion-wrapper">
          <div className="email-profile">
            <span>Sender Settings</span>
            <div className="sender-image">
              <img alt="hello" src={emailSettings.generalSettings.companyLogo} />
              <Button onClick={() => setOpenSettingsModal(true)} className="edit-profile-btn">
                Customize
              </Button>
            </div>
          </div>
          {Object.keys(emails).map((key) => (
            <div key={key}>
              <Row className="email-accordion-header">
                <div className="email-accordion-icon">{emails[key].icon}</div>
                <div className="email-accordion-title">{emails[key].title}</div>
              </Row>
              {Object.keys(emails[key].items).map((item) => (
                <AccordionContent
                  key={item}
                  emailKey={item}
                  config={emails[key].items[item]}
                  data={emailSettings[item]}
                  allSettings={emailSettings}
                  generalSettings={emailSettings.generalSettings}
                  isDisabled={isDisabled}
                />
              ))}
            </div>
          ))}
        </div>
        <GeneralSettingsModal
          data={emailSettings.generalSettings}
          isOpen={openSettingsModal}
          onCancel={() => setOpenSettingsModal(false)}
        />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Emails;
