import React, { useState } from 'react';
import { Button, Col, Row, Divider, Avatar, Tooltip, Slider } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { FEATURES_LIST_1, FEATURES_LIST_2 } from 'constants/index';

import CircleTickIcon from 'components/Icons/CircleTickIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import ServicesIcon from 'components/Icons/ServicesIcon';
import AddUsers from 'components/Icons/AddUsers';
import AddTrialUsers from 'components/Icons/AddTrialUsers';
import DollarDiscountIcon from 'components/Icons/DollarDiscountIcon';
import images from 'constants/images';
import PlansInfoModals from './PlansInfoModals/PlansInfoModals';

import './PlansInfo.scss';

const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

const maxUsersInvitation = parseInt(process.env.REACT_APP_MAX_USER_INVITE) || 25;

const PlansInfo = ({
  user,
  usersTeam,
  pendingUsersTeam,
  startPolling,
  stopPolling,
  handleStripeClick,
  stripeLoading,
  refetchInvoices,
  handleChangeCard,
  refetchTeamMembers,
  refetchUser,
  setLoading,
  // createPayPalSubscription,
}) => {
  const [plan, setPlan] = useState(
    (!user.paymentStatus.canLock && user.paymentStatus.subType) ||
      user.paymentStatus.subType ||
      'monthly'
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [newUsersCount, setNewUsersCount] = useState(0);

  const renderFeaturesList = (list, isActive) => {
    return list.map((item) => (
      <Row key={item.text} className={`feature-list ${isActive ? '' : 'feature-list-disabled'}`}>
        <CircleTickIcon className="feature-list-check-icon" />
        <div className="feature-list-info">
          <span className="feature-list-text">{item.text}</span>
          <Tooltip title={item.tooltip} overlayClassName="feature-list-info-tooltip">
            <InfoIcon className="feature-list-info-icon" />
          </Tooltip>
        </div>
      </Row>
    ));
  };

  const getTrialTime = () => {
    if (!user) {
      return 666;
    }

    const timespan = new Date() - new Date(user.stopperAnchor || user.createdAt);
    const dayspan = timespan / 1000 / 60 / 60 / 24;

    return daystrial - dayspan;
  };

  const changeUserTeamCount = (val) => {
    setNewUsersCount(val);
  };

  const { everPayed, isLegacy, didCancel, validTill, plan: stripePlan } = user?.paymentStatus;
  const c_user_subtype = (!user?.paymentStatus?.canLock && user?.paymentStatus?.subType) || '';
  const trialTime = getTrialTime();
  const trial = trialTime >= 0 ? true : false;
  const discountInformation = stripePlan?.discountInformation || {};
  const discountDuration = discountInformation?.durationInMonths || 0;
  const discountStartDate = moment.unix(discountInformation?.startDate) || '';
  const discountEndDate = discountStartDate?.add(discountDuration, 'M')?.format('MMM Do, YYYY');
  const discountValue = discountInformation?.percent
    ? discountInformation?.percent / 100 || 1
    : discountInformation?.fixed
    ? discountInformation?.fixed
    : 1;
  let validDate = undefined;
  if (daystrial > moment().diff(moment(user.createdAt), 'days')) {
    validDate = moment()
      .add(daystrial - moment().diff(moment(user.createdAt), 'days'), 'days')
      .toDate();
  } else {
    validDate =
      user?.paymentStatus?.validTill &&
      moment(user?.paymentStatus?.validTill).format('MMM Do, YYYY');
  }
  const userPlanType = user.planType
    ? user.planType
    : new Date(user.createdAt) > new Date(process.env.REACT_APP_PLAN_REVISE_DATE)
    ? 'new'
    : '';
  const planValue =
    userPlanType === 'new' && validDate && !didCancel
      ? plan === 'yearly'
        ? 96
        : 10
      : userPlanType === '2025' || !validDate || didCancel
      ? plan === 'yearly'
        ? 120
        : 19
      : plan === 'yearly'
      ? 60
      : 8;
  const totalValue =
    ((stripePlan?.planInformation?.amount || 0) / 100 || planValue) * (usersTeam.length + 1);
  const totalDiscountValue = discountInformation?.percent
    ? totalValue * discountValue
    : discountInformation?.fixed
    ? discountValue
    : 0;

  let { nextPaymentDate } = user?.paymentStatus;
  let renewaldate = nextPaymentDate && moment(nextPaymentDate).format('MMM Do, YYYY');
  let validdate = undefined;

  if (daystrial > moment().diff(moment(user.createdAt), 'days')) {
    validdate = moment()
      .add(daystrial - moment().diff(moment(user.createdAt), 'days'), 'days')
      .format('MMM Do, YYYY');
  } else {
    validdate = validTill && moment(validTill).format('MMM Do, YYYY');
  }

  if (isLegacy) {
    nextPaymentDate = null;
    renewaldate = null;
  } else if (!renewaldate && trial) {
    renewaldate = moment().add(trialTime, 'days').format('MMM Do, YYYY');
  }
  console.log(
    renewaldate,
    trial,
    nextPaymentDate,
    didCancel,
    planValue,
    'renewaldate, trial, nextPaymentDate'
  );

  return (
    <Row className="plans-info">
      <Col xs={24} md={16}>
        <p className="plans-content-header">Current Plan</p>

        <Row className="plans-price">
          <span>${(totalValue - totalDiscountValue)?.toFixed(2)?.replace(/[.,]00$/, '')}</span>
          <span>{plan === 'monthly' ? '/month' : '/year'}</span>
        </Row>

        {totalDiscountValue ? (
          <Row className="plans-price--total-before-discount">
            <span>${totalValue?.toFixed(2)?.replace(/[.,]00$/, '')}</span>
          </Row>
        ) : null}

        <Row className="plans-price plans-price-subheading">
          For total&nbsp;
          <strong>
            {usersTeam.filter((team) => team.emails[0].verified !== false).length
              ? `${1 + usersTeam.filter((team) => team.emails[0].verified !== false).length} users`
              : '1 user'}{' '}
            &nbsp;&nbsp;
            <Divider className="plan-divider" type="vertical" />
          </strong>
          {''}&nbsp;{plan === 'yearly' ? 'Annual' : 'Monthly'} Plan
        </Row>

        {discountInformation?.fixed || discountInformation?.percent ? (
          <Row className="plans-price plans-price-subheading--discount">
            <DollarDiscountIcon />
            {discountInformation?.fixed ? '$' : ''}
            {discountInformation?.percent || discountInformation?.fixed}
            {discountInformation?.percent ? '%' : ''} discount applied
          </Row>
        ) : null}

        {discountDuration ? (
          <Row className="plans-price-discount-expiry">
            <span>Discount expires on {discountEndDate} </span>
          </Row>
        ) : null}

        {pendingUsersTeam.length ? (
          <Row className="plans-price plans-price-subheading">
            Payment pending for &nbsp;
            <strong>
              {pendingUsersTeam.length > 1
                ? `${pendingUsersTeam.length} users`
                : `${pendingUsersTeam.length} user`}
            </strong>{' '}
          </Row>
        ) : null}

        <Row className={`plans-payment-buttons ${!everPayed ? 'trial' : ''}`}>
          {!everPayed && (
            <div className="trial-user-select-plan">
              <p>
                <AddTrialUsers /> How many members are in your team ?
              </p>
              <p className="trial-plan-count">{usersTeam.length + newUsersCount + 1}</p>
              <Divider dashed={true} />
              <Slider
                defaultValue={10}
                tooltipVisible={false}
                value={usersTeam.length + newUsersCount + 1}
                min={0}
                max={maxUsersInvitation}
                onChange={changeUserTeamCount}
              />
            </div>
          )}

          {everPayed ? (
            <Button
              className="large-btn secondary-btn add-users-button"
              type="primary"
              icon={c_user_subtype === plan ? <AddUsers /> : null}
              // loading={stripeLoading === REACT_APP_STRIPE_MONTHLY_PLAN_ID}
              onClick={() => setShowAddUserModal(true)}>
              MANAGE PLAN & USERS
            </Button>
          ) : null}

          {everPayed ? (
            <Button
              className="large-btn grey-btn plans-switch-button"
              type="primary"
              onClick={() => setShowChangeModal(true)}>
              CHANGE PAYMENT METHOD
            </Button>
          ) : null}

          {everPayed && !didCancel && validdate ? (
            <u className="cancel-plan-text" onClick={() => setShowCancelModal(true)}>
              {' '}
              Cancel Plan{' '}
            </u>
          ) : null}

          {
            <p className="cancel-plan-text-info">
              {renewaldate && !trial
                ? !didCancel
                  ? `Payment on ${renewaldate}`
                  : `Canceled, expires on ${renewaldate}`
                : didCancel && !isLegacy
                ? validdate
                  ? `Canceled but valid till ${validdate}`
                  : 'Canceled'
                : trial
                ? `Trial expires on ${renewaldate}`
                : !everPayed
                ? `Trial expired ${Math.abs(trialTime).toFixed(0)} days ago`
                : ''}
            </p>
          }
        </Row>

        <Row className="start-new-plan-section">
          {!everPayed && (
            <Button
              className="primary-btn start-plan-button"
              type="primary"
              // loading={stripeLoading === REACT_APP_STRIPE_MONTHLY_PLAN_ID}
              onClick={() => setShowAddUserModal(true)}>
              START NEW PLAN
            </Button>
          )}
        </Row>

        <Row className="plans-review">
          <div className="testimony">
            <Row className="plans-reviewer">
              <Avatar src={images.PLAN} size={64} />
              <Col className="plans-reviewer-info">
                <span>Hadar Chen</span>
                <span>Co-founder, Hunt Digital</span>
              </Col>
            </Row>
            <p>
              Prospero is helping us to achieve exhilarating and invigorating results. It's a great
              boost to any business and its easy to use!
            </p>
          </div>
          <ServicesIcon className="plans-review-service-icon" />
        </Row>
      </Col>

      <Col xs={24} md={8}>
        <p className="plans-information-header">This Is What You Get</p>
        <Row className="plans-information" gutter={[32, 16]}>
          <Row span={7} className="list">
            {renderFeaturesList(FEATURES_LIST_1, true)}
          </Row>
          <Row span={9} className="list">
            {renderFeaturesList(FEATURES_LIST_2, usersTeam.length + newUsersCount ? true : false)}
          </Row>
        </Row>
      </Col>

      <PlansInfoModals
        user={user}
        plan={plan}
        trial={trial}
        trialTime={trialTime}
        startPolling={startPolling}
        stopPolling={stopPolling}
        usersTeam={usersTeam}
        refetchTeamMembers={refetchTeamMembers}
        refetchInvoices={refetchInvoices}
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
        showChangeModal={showChangeModal}
        setShowChangeModal={setShowChangeModal}
        showAddUserModal={showAddUserModal}
        newUsersCount={newUsersCount}
        setPlan={setPlan}
        setShowAddUserModal={setShowAddUserModal}
        pendingUsersTeam={pendingUsersTeam}
        // createPayPalSubscription={createPayPalSubscription}
        renewaldate={renewaldate}
        c_user_subtype={c_user_subtype}
        handleStripeClick={handleStripeClick}
        handleChangeCard={handleChangeCard}
        stripeLoading={stripeLoading}
        refetchUser={refetchUser}
        setLoading={setLoading}
      />
    </Row>
  );
};

PlansInfo.defaultProps = {
  stripeLoading: false,
  user: {},
  refetchInvoices: () => {},
  refetchTeamMembers: () => {},
};

PlansInfo.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  usersTeam: PropTypes.instanceOf(Array),
  pendingUsersTeam: PropTypes.instanceOf(Array),
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  stripeLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  refetchInvoices: PropTypes.func,
  handleStripeClick: PropTypes.func.isRequired,
  handleChangeCard: PropTypes.func.isRequired,
  refetchTeamMembers: PropTypes.func.isRequired,
  refetchUser: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  // createPayPalSubscription: PropTypes.func.isRequired,
};

export default PlansInfo;
